<template>
  <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
    <v-list-item v-if="aee" class="">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium text-h6">
          Detalhes do Atendimento
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-row>
            <v-col>
              <span>Data: {{ aula.data }}</span>
            </v-col>
            <v-col>
              <span>Horário: {{ aula.horario?.descricao }}</span>
            </v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-else :class="localAula?.color">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          localAula?.tipo_de_aula
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content v-if="aee">
        <v-list-item-title class="mt-4">Metas</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula?.metas }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Atividades</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula?.atividades }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Situação</v-list-item-title>
        <v-list-item-title>
          <v-chip outlined :color="localAula?.color">
            <v-icon left color="grey">mdi mdi-label </v-icon
            >{{ localAula?.confirmada === 1 ? "Confirmada" : "Não Confirmada" }}
          </v-chip>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title>Data</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula?.data_formatada }}
          {{ " - " }}
          {{ localAula?.horario ? localAula?.horario.descricao : "[- - -]" }}
        </p>
        <v-list-item-title class="mt-4">Conteúdo</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula?.conteudo }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Metodología</v-list-item-title>
        <p class="font-weight-light text-justify">
          {{ localAula?.metodologia }}
        </p>
        <v-divider></v-divider>
        <v-list-item-title class="mt-4">Situação</v-list-item-title>
        <v-list-item-title>
          <v-chip label filter outlined :color="localAula?.color">
            {{ localAula?.descricaoSituacao }}
          </v-chip>
        </v-list-item-title>
        <v-list-item-title class="mt-4">Motivo</v-list-item-title>
        <p class="font-weight-light">
          {{ localAula?.motivo ? localAula?.motivo : "Sem motivo." }}
        </p>

        &nbsp;
        <v-divider class="my-8"></v-divider>

        <v-list-item-title class="mb-5"
          >Gerenciamento da Aula:
          <span class="font-weight-medium">#{{ localAula?.id }}</span></v-list-item-title
        >
        <v-card-text
          v-for="dado in localAula?.audits"
          :key="dado.id"
          class="py-0 marginEsquerdaNegativo"
        >
          <v-timeline align-top dense>
            <v-timeline-item color="pink" small>
              <v-row class="pt-1">
                <v-col cols="12" class="caption">
                  Tipo: <strong>{{ traducaoAuditoria(dado.event) }}</strong>
                  <div class="text-caption">
                    Quando:
                    <span>{{ dado.created_at }}</span>
                  </div>
                  <div class="text-caption">
                    Responsável:
                    <span>{{ dado.username }}</span>
                  </div>
                  <div class="text-caption">
                    IP:
                    <span>{{ dado.ip_address }}</span>
                  </div>
                  <v-divider />
                  <div class="text-caption font-weight-bold" v-if="dado.new_values.length !== 0">
                    Modificações realizadas:
                  </div>
                  <div
                    class="text-caption"
                    v-for="item in Object.keys(dado.new_values)"
                    :key="item"
                  >
                    <span class="font-weight-medium">{{ cleanString(item) }}:</span>
                    <template v-if="typeof dado.new_values[item] === 'string'">
                      {{ decodeUnicode(dado.new_values[item]) }}
                    </template>
                    <template v-else>
                      {{ dado.new_values[item] }}
                    </template>
                  </div>

                  <v-divider />
                  <div class="text-caption font-weight-bold" v-if="dado.old_values.length !== 0">
                    Dados anteriores:
                  </div>
                  <div
                    class="text-caption"
                    v-for="item in Object.keys(dado.old_values)"
                    :key="item"
                  >
                    <span class="font-weight-medium">{{ item }}:</span> {{ dado.old_values[item] }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    aee: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // this.listarAula();
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  methods: {
    decodeUnicode(str) {
      if (typeof str === "string") {
        str = str.replace(/null/g, "").replace(/,{2,}/g, ", ");
        return str.replace(/\\u[\dA-F]{4}/gi, function (match) {
          return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
        });
      }
      return str;
    },
    traducaoAuditoria(texto) {
      const textos = {};
      textos.created = "Criado";
      textos.updated = "Atualizado";
      textos.deleted = "Deletado";
      return textos[texto];
    },
    cleanString(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1)).replace("_", " ");
    },
    async listarAula() {
      const { turma_id, disciplina_id } = this.$route.params;
      const response = await this.$services.aulasService.syncAll({ turma_id, disciplina_id });
      this.aulasList = response.data;
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>
.marginEsquerdaNegativo {
  margin-left: -40px;
  width: 20px;
}
</style>
