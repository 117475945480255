<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          RELATÓRIO ANUAL DE DESENVOLVIMENTO DO ALUNO
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>RELATÓRIO ANUAL DE DESENVOLVIMENTO DO ALUNO</v-card-title>
          <v-card-text>
            <h4>Turma: {{ gestao ? gestao.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ gestao ? gestao.matricula.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor.nome }} | Disciplina:
              {{ diario.disciplina ? diario.disciplina.descricao : "" }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="relato" rules="required" v-slot="{ errors }">
              <e-label>{{ question }}</e-label>
              <v-textarea rows="10" solo dense v-model="form.relato" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      submittingForm: false,
      form: {
        relato: "",
      },
      gestao: null,
      painel: null,
      question:
        "RELATE AS PRINCIPAIS INFORMAÇÕES REFERENTES AO DESENVOLVIMENTO MOTOR, SOCIOEMOCIONAL E COGNITIVO DO ALUNO (A) NESTE ANO LETIVO, BEM COMO SUA EXPERIÊNCIA DE TRABALHO COM A FAMÍLIA, COM OS DEMAIS PROFESSORES E COM A GESTÃO ESCOLAR.",
      matricula: null,
      gestao_de_aulas: [],
    };
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestao_id } = this.$route.params;
        this.form.gestao_id = gestao_id;
        this.$toast.info("Relatório salvo com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDesenvolvimentoAnual(this.form);
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "gestoesDeAulasAuxiliares" });
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestao_id } = this.$route.params;

        const response =
          await this.$services.educacaoInclusivaService.carregarrFichaDeDesenvolvimentoAnual(
            gestao_id
          );
        if (response.ficha) {
          this.form = response.ficha;
        }
        this.lancarRelatorio =
          this.$constants.FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_FUNDAMENTAL_FINAIS;
        this.matricula = response.matricula;
        this.gestao_de_aulas = response.gestao_de_aulas;
        this.gestao = response.gestao;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
