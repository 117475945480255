<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Bimestral
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.bimestre" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.bimestres"
                :key="item.value"
                @click="() => editarPlano(item.value ? item.value : 0)"
              >
                <v-badge v-if="form.bimestre === item.value" color="success">
                  {{ item.desc }}
                </v-badge>
                <div v-else>{{ item.desc }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row
          v-for="plano in parseInt(gestaoDeAula.turma.curso_id, 10) === 10
            ? $constants.plano_bimestrais_infantil
            : $constants.plano_bimestrais_fundamental"
          :key="plano.form"
        >
          <v-col cols="12">
            <ValidationProvider :name="plano.descricao" rules="required" v-slot="{ errors }">
              <e-label>{{ plano.descricao }}</e-label>
              <v-textarea solo dense v-model="form[plano.form]" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="verificarBloqueioCampo"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      user: {},
      verificarBloqueioCampo: false,
      submittingForm: false,
      form: {
        bimestre: 0,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
    };
  },
  methods: {
    verificarBloqueioCampoConformeRegraDeNegocio(data) {
      if (data === "") {
        this.verificarBloqueioCampo = false;
        return;
      }
      if (
        (this.submittingForm || this.overlayOpen || parseInt(data?.professor_antigo_id, 10) > 0) &&
        this.user.roles[0].name === "professor"
      ) {
        this.verificarBloqueioCampo = true;
        return;
      }
      this.verificarBloqueioCampo = false;
    },
    async editarPlano(bimestre) {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          bimestre
        );
        if (response !== "") {
          this.verificarBloqueioCampoConformeRegraDeNegocio(response);
          this.form = response;
          this.$loaderService.close();
          return;
        }
        this.verificarBloqueioCampoConformeRegraDeNegocio(response);
        this.form = {
          bimestre,
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.bimestre = bimestre;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        this.$toast.success("Plano bimestral salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      const { bimestre } = this.form;
      try {
        this.$loaderService.open("Carregando dados...");
        this.user = await this.$services.authService.getCurrentUser();
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);
        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId,
          bimestre
        );
        if (response !== "") {
          this.form = response;
          this.verificarBloqueioCampoConformeRegraDeNegocio(response);
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
