<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h1>
          <v-btn color="primary" x-small text @click="$router.go(-1)">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário de Atendimento
        </h1>
      </v-col>

      <v-col cols="12">
        <p>
          <v-chip color="blue lighten-1 white--text"
            >ALUNO: {{ aluno ? aluno.nomecompleto : "- - -" }}
          </v-chip>
        </p>
        <v-select
          :items="meses"
          item-text="descricao"
          item-value="id"
          v-model="selectMonth"
          return-object
          label="Selecione um mês"
        ></v-select>
        <v-select
          :items="disciplinas"
          item-value="id"
          v-model="disciplina_id"
          item-text="descricao"
          label="Escolha uma disciplina"
          @change="(event) => getPerguntas(event)"
        ></v-select>

        <v-list v-if="form[0]">
          <v-list-group :value="true" v-for="pergunta in form" :key="pergunta.id" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="pergunta.subtitulo"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="child in pergunta.children" :key="child.id">
              <v-list-item-content>
                <p>{{ child.questao }}</p>
                <v-select :items="opcoes" v-model="child.resposta" label="Escolha"></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-btn
          :disabled="!form[0]"
          :loading="submittingForm"
          @click="() => submitForm()"
          color="success"
          type="submit"
          >Enviar Dados</v-btn
        >
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    selectMonth() {
      this.getPerguntas(this.disciplina_id);
    },
  },
  data() {
    return {
      table: {
        headers: [
          { text: "Disciplina", value: "descricao" },
          { text: "ALCANÇADO", value: "codigo" },
          { text: "EM PROCESSO", value: "codigo_inep" },
          { text: "NÃO", value: "nomecompleto" },
          { text: "NÃO AVALIADO", value: "actions", sortable: false },
        ],
      },
      opcoes: ["A", "P", "N", "NV"],
      disciplinas: [],
      form: [],
      submittingForm: false,
      disciplina_id: null,
      aluno: null,
      meses: [],
      selectMonth: {
        id: 2,
        descricao: "Fevereiro",
      },
    };
  },
  methods: {
    async loadData() {
      const { gestaoId } = this.$route.params;
      const data = await this.$services.gestoesDeAulasAuxiliaresService.listarDisciplinas(
        parseInt(gestaoId, 10)
      );
      this.disciplinas = data.data.disciplinas;
      this.aluno = data.data.aluno;
      this.meses = await this.$services.anosService.meses();
    },
    async getPerguntas(value) {
      this.form = [];
      const idDisciplina = value;
      const { gestaoId } = this.$route.params;
      const data = await this.$services.gestoesDeAulasAuxiliaresService.listarPerguntas(
        parseInt(idDisciplina, 10),
        gestaoId,
        this.selectMonth.id
      );

      this.form = data.data;
    },
    async submitForm() {
      if (!this.form[0].children) return;
      const { gestaoId } = this.$route.params;
      await this.$services.gestoesDeAulasAuxiliaresService.salvar(
        this.form,
        this.disciplina_id,
        gestaoId,
        this.selectMonth.id
      );
      this.$toast.success("Relatório salvo com sucesso");
    },
  },
};
</script>
