import Model from "./Model";

export default class GestaoDeAula extends Model {
  constructor(relacao) {
    super();
    Object.assign(this, relacao);
    this.nomeDisciplina = this.disciplina ? this.disciplina.descricao : "- - -";
    this.nomeTurma = this.turma ? this.turma.descricao : "- - -";
    this.nomeProfessor = this.professor ? this.professor.nome : "- - -";
    this.nomeFranquia = this.turma && this.turma.franquia ? this.turma.franquia.descricao : "- - -";
    this.notifiq = this.turma && this.turma.notifiq ? this.turma.notifiq : "- - -";
  }
}
