<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'professores' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Professor
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Nome Completo" rules="required" v-slot="{ errors }">
              <e-label>Nome Completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.nome" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Email</e-label>
            <v-text-field v-model="form.email" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <ValidationProvider name="CPF" rules="required" v-slot="{ errors }">
              <e-label>Número do CPF</e-label>
              <v-text-field
                :error-messages="errors"
                v-mask="'###.###.###-##'"
                v-model="form.cpf"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Telefone/celular</e-label>
            <v-text-field
              v-model="form.numero_celular"
              dense
              solo
              @keyup="mask(form.numero_celular, 1)"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Telefone/celular 2</e-label>
            <v-text-field
              v-model="form.numero_celular_2"
              dense
              solo
              @keyup="mask(form.numero_celular_2, 0)"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Formação</e-label>
            <v-text-field v-model="form.formacao" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <e-label>Identificação única(INEP)</e-label>
            <v-text-field v-model="form.codigo" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Carga horário exercício</e-label>
            <v-text-field v-model="form.ch_exercicio" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <ValidationProvider name="Data de nascimento" rules="required" v-slot="{ errors }">
              <e-label>Data de nascimento</e-label>
              <e-date-picker :error-messages="errors" v-model="form.dataNascimento" />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Matrícula</e-label>
            <v-text-field v-model="form.matricula" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Vinculo</e-label>
            <e-autocomplete
              :items="$constants.vinculo"
              :return-object="false"
              :item-value="(value) => value.desc"
              :item-text="(value) => value.desc"
              v-model="form.vinculo"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Filiação 1</e-label>
            <v-text-field v-model="form.filiacao1" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Filiação 2</e-label>
            <v-text-field v-model="form.filiacao2" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Sexo</e-label>
            <e-autocomplete
              :items="$constants.sexoList"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.sexo_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Cor/Raça</e-label>
            <e-autocomplete
              :items="$constants.corOuRacaList"
              :return-object="false"
              v-model="form.cor_ou_raca"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Nacionalidade</e-label>
            <e-autocomplete
              :items="$constants.nacionalidadeList"
              :return-object="false"
              v-model="form.naturalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>País de nacionalidade</e-label>
            <v-text-field v-model="form.nacionalidade" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Município de nascimento</e-label>
            <v-text-field v-model="form.municipalidade" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>UF de nascimento</e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.estadualidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="8">
            <e-label
              >Profissional escolar com deficiência, transtorno do espectro autista ou altas
              habilidades/superdotação</e-label
            >
            <v-switch v-model="form.especial"></v-switch>
          </v-col>
          <v-col cols="12">
            <h4>
              Tipo de deficiência, transtorno do espectro autista ou altas habilidades/superdotação
            </h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Deficiência</e-label>

            <e-autocomplete
              clearable
              :items="$constants.tipoDeDeficienciaList"
              :return-object="false"
              v-model="form.tipos_de_deficiencia"
              label="Selecione uma opção"
              multiple
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Transtorno do espectro autista</e-label>

            <e-autocomplete
              clearable
              :items="$constants.tipoDeTranstornoDoEspectroAutistaList"
              :return-object="false"
              v-model="form.tipos_de_transtornos"
              label="Selecione uma opção"
              multiple
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Altas habilidades/superdotação</e-label>

            <e-autocomplete
              clearable
              :items="$constants.tipoDeAltasHabilidadesList"
              :return-object="false"
              v-model="form.tipos_de_altas_habilidades"
              label="Selecione uma opção"
              multiple
              dense
              solo
            />
          </v-col>
          <v-col cols="12">
            <h4>DADOS SOBRE A ÁREA RESIDENCIAL DO(A) PROFISSIONAL(A) ESCOLAR</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> País de residência </e-label>
            <v-text-field v-model="form.pais_residencia" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label> CEP </e-label>
            <v-text-field v-mask="'#####-###'" value="" v-model="form.cep" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> UF </e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.uf_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Município de residência </e-label>
            <v-text-field value="" v-model="form.municipio_residencia" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Zona de Residência </e-label>
            <e-autocomplete
              :items="$constants.zonaResidenciaList"
              :return-object="false"
              v-model="form.zona_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Localização diferenciada de residência</e-label>
            <e-autocomplete
              :items="$constants.localizacaoDiferenciadaDeResidenciaList"
              :return-object="false"
              v-model="form.zona_diferenciada_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Tipo de Professor:</e-label>
            <ValidationProvider name="Nome Completo" rules="required" v-slot="{ errors }">
              <e-autocomplete
                :items="$constants.rolesParaProfessores"
                :return-object="false"
                v-model="form.role_id"
                :error-messages="errors"
                :item-value="(role) => role.id"
                :item-text="(role) => role.name"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Franquia(s) do usuário:</e-label>
            <e-autocomplete
              :items="franquiasList"
              :return-object="false"
              v-model="form.user.franquias_permitidas"
              :item-value="(franquia) => franquia.id"
              :item-text="(franquia) => franquia.descricao"
              :loading="carregandoFranquias"
              label="Selecione uma opção"
              dense
              multiple
              solo
            />
          </v-col>

          <v-col cols="10">
            <template>
              <!--UPLOAD-->
              <form enctype="multipart/form-data" novalidate>
                <v-file-input
                  placeholder="Selecione uma foto"
                  accept="image/*"
                  prepend-icon="mdi-camera"
                  label="Foto"
                  type="file"
                  :name="uploadFieldName"
                  @change="filesChange($event)"
                ></v-file-input>
              </form>
            </template>
          </v-col>
          <v-col cols="2">
            <div v-if="editing" class="my-2">
              <v-btn
                color="secondary"
                fab
                x-small
                dark
                @click="imagem_dialog = true"
                style="position: relative; top: 15px"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </div>
            <v-dialog v-model="imagem_dialog" width="500">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2"> Foto atual </v-card-title>

                <v-card-text class="my-5 text-center">
                  <img width="200px" :src="foto_do_professor_quando_ele_tiver_salvo" alt="" />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="imagem_dialog = false"> Fechar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // ...mapGetters("Professores", ["professores", "professoresList", "carregandoProfessores"]),
    ...mapGetters("Franquias", ["carregandoFranquias"]),
  },
  mounted() {
    this.loadFormData();
    this.loadData();
  },
  data() {
    return {
      professores: [],
      professoresList: [],
      franquiasList: [],
      carregandoProfessores: false,
      submittingForm: false,
      form: { user: {} },
      /* Abaixo são dados relacionados à imagem */
      uploadFieldName: "photos",
      foto_do_professor_quando_ele_tiver_salvo: "",
      base64Img: "",
      countImagem: false,
      currentStatus: null,
      foto_upload: null,
      imagem_carregada: false,
      msg_initial: true,
      imagem_dialog: false,
    };
  },
  watch: {
    form: {
      async handler(formValue) {
        try {
          const cepValue = this.$services.cepService.getCepInteiro(formValue.cep);
          if (cepValue) {
            const localizacao = await this.$services.cepService.buscarDadosDoCep(cepValue);
            this.form.municipio_residencia = localizacao.cidade;
            this.form.uf_residencia = localizacao.estado;
          }
        } catch (error) {
          this.$handleError("Erro ao buscar informações do CEP");
        }
      },
      deep: true,
    },
  },
  methods: {
    // ...mapActions("Professores", ["loadProfessores"]),
    ...mapActions("Franquias", ["loadFranquias"]),

    async listarProfessores() {
      try {
        const { professor_id } = await this.$route.params;
        const data = await this.$services.professoresService.pegarProfessor(professor_id);
        this.professores = data.data;
        const professor = data.data[0];
        if (professor) {
          this.form = {
            ...professor,
            user: {
              ...professor.user,
              franquias_permitidas: [...professor.user.franquias_permitidas],
            },
          };
          if (professor.imagem) {
            // console.log("caiu condição imagem");
            await this.downloadImagem(professor.id);
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.professoresService.atualizarProfessor(this.form);
          if (this.foto_upload) {
            // console.log("tem foto para ser atualizada = True");
            await this.uploadImage(this.form.id);
          }
        } else {
          const response = await this.$services.professoresService.criarProfessor(this.form);
          await this.uploadImage(response.id);
          await this.mask(this.form.numero_celular ?? null, 1);
          await this.mask(this.form.numero_celular_2 ?? null, 0);
        }
        this.$toast.success("Professor Atualizada com sucesso"); // voltar_aqui
        this.$router.push({ name: "professores" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadData() {
      this.franquiasList = await this.$services.franquiasService.listAllSelect();

      try {
        if (this.professores.length === 0) {
          this.$loaderService.open("Carregando Professor");
        }
        await Promise.all([this.listarProfessores(), this.loadFranquias()]);
        this.$loaderService.close();
        this.$forceUpdate();
        this.loadFormData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadFormData() {
      if (!this.professores || !this.editing) {
        return;
      }
      const { professor_id } = await this.$route.params;

      if (professor_id) {
        this.carregandoProfessores = await true;
        this.$loaderService.open("Carregando Professor(a)");
      }
      const result = await this.professores.filter((p) => p.id === professor_id);
      let professor = null;
      if (result) {
        this.carregandoProfessores = await false;
        this.$loaderService.close();
        professor = result[0] ? result[0] : null;
      }
      if (!professor || this.form.id) {
        return;
      }
      this.form = await {
        ...professor,
        user: {
          ...professor.user,
          franquias_permitidas: [...professor.user.franquias_permitidas],
        },
      };
    },

    mask(v, campo) {
      if (!v) return;

      let r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else {
        r = r.replace(/^(\d*)/, "($1");
      }
      if (campo === 1 && v.length >= 1) {
        this.form.numero_celular = r;
        return;
      }
      if (!campo && v.length >= 1) {
        this.form.numero_celular_2 = r;
      }
    },

    /* ABAIXO SÃO TODOS OS MÉTODOS RELACIONADOS À IMAGEM */
    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage(
          "professores/upload-image",
          this.foto_upload,
          id
        );
      } catch (error) {
        this.$handleError(error);
      }
    },

    filesChange(e) {
      this.foto_upload = e;
    },

    async downloadImagem(professor_id) {
      // console.log("baixando imagem....");
      const url = "professores/download-image-professor";
      const response = await this.$services.imageService.downloadImagemV2(
        url,
        parseInt(professor_id, 10)
      );
      this.foto_do_professor_quando_ele_tiver_salvo = await response;
    },
  },
};
</script>

<style scoped>
.buttonAbrirDialog {
  font-size: 1.2em;
}
</style>
