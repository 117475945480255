<template>
  <div>
    <span>
      <v-menu v-if="user && $can(['aulas.mudarSituacao'])" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip label :color="color" class="text-center" x-small v-bind="attrs" v-on="on">
            {{ descricaoSituacao }}
          </v-chip>
        </template>
        <v-list>
          <v-list-item
            v-for="situacao of $constants.situacoesAulas"
            :key="situacao.descricao"
            link
            @click="() => verficaMotivo(situacao.descricao)"
          >
            <v-list-item-title>{{ situacao.descricao }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-chip v-else label :color="color" x-small>
        {{ descricaoSituacao }}
      </v-chip>
    </span>
    <v-progress-linear
      v-show="atualizando"
      indeterminate
      striped
      color="primary"
    ></v-progress-linear>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="primary">
              <span class="text-h5 white--text">Informar motivo</span>
            </v-card-title>
            <v-col cols="12">
              <v-textarea
                filled
                autofocus
                v-model="motivo"
                name="input-7-4"
                label="Descreva o motivo da falta"
                value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                dense
                solo
              ></v-textarea>
            </v-col>
            <!-- application/pdf, application/msword, application/msword,  -->
            <v-col v-if="situacao_escolhida == 'Aula rejeitada por falta'">
              <form enctype="multipart/form-data" novalidate>
                <v-file-input
                  placeholder="Apenas imagem png, jpg, jpeg e etc..."
                  accept="image/*"
                  :label="[
                    aula.imagem
                      ? 'Enviar novo atestado médico'
                      : 'Enviar atestado médico (opcional)',
                  ]"
                  type="file"
                  :name="uploadFieldName"
                  @change="filesChange($event)"
                ></v-file-input>
                <p></p>
              </form>
            </v-col>
            <v-col v-if="aula.imagem" cols="12" class="ml-3">
              <v-chip text-color="white" color="green"> 1 atestado já foi enviado </v-chip>
              <v-btn
                href=""
                color="green"
                text-color="white"
                fab
                x-small
                dark
                class="ml-2 fileButton"
                @click="downloadAtestado(aula.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="!aula.imagem" cols="12" class="ml-3">
              <v-chip text-color="white" color="red"> Nenhum atestado enviado</v-chip>
            </v-col>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog = false"> Cancelar </v-btn>
              <v-btn
                color="primary"
                text
                @click="() => changeSituacaoJustificacao(descricaoSituacaoJustificativa)"
              >
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    aula: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      atualizando: false,
      descricaoSituacao: this.aula.descricaoSituacao,
      color: this.aula.color,
      dialog: false,
      motivo: null,
      descricaoSituacaoJustificativa: null,
      atestado_upload: "",
      uploadFieldName: "",
      situacao_escolhida: "",
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async verficaMotivo(novaSituacao) {
      this.motivo = this.aula.motivo;
      if (novaSituacao === "Aula inválida" || novaSituacao === "Aula rejeitada por falta") {
        this.situacao_escolhida = novaSituacao;
        if (this.situacao_escolhida === "Aula inválida") {
          this.situacao_escolhida = "";
        }
        this.descricaoSituacaoJustificativa = novaSituacao;
        this.dialog = !this.dialog;
        return;
      }
      this.changeSituacao(novaSituacao);
    },
    async changeSituacaoJustificacao(novaSituacao) {
      // console.log("mudando situação");
      // console.log(this.aula);
      await this.uploadImage(parseInt(this.aula.id, 10));
      this.dialog = await !this.dialog;
      this.aula.motivo = await this.motivo;
      this.atualizando = await true;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula,
          novaSituacao
        );
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
    async changeSituacao(novaSituacao) {
      this.atualizando = true;
      this.aula.motivo = null;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula,
          novaSituacao
        );
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
    filesChange(e) {
      this.atestado_upload = e;
      // console.log("nova imagem inserida");
      // console.log(this.atestado_upload);
    },
    async uploadImage(id) {
      // console.log("fazendo upload...");
      try {
        await this.$services.imageService.uploadImage(
          "aulas/upload-atestado-professor",
          this.atestado_upload,
          id
        );
        this.atestado_upload = await null;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async downloadAtestado(aula_id) {
      // console.log("baixando imagem....");
      const pos = this.aula.imagem.lastIndexOf(".");
      const extension = this.aula.imagem.slice(pos);
      const url = "aulas/download-image-atestado";
      await this.$services.imageService.downloadImagemV3(url, parseInt(aula_id, 10), extension);
    },
  },
};
</script>
