import municipios from "./municipios";

export const $constants = {
  tiposDeMediacaoDidaticoPedagogica: ["Presencial", "Semipresencial", "Educação a distância – EAD"],
  localDeFuncionamentoDiferenciadoDaTurma: [
    "Sala anexa",
    "Unidade de atendimento socioeducativo",
    "Unidade prisional",
    "A turma não está em local de funcionamento diferenciado",
  ],
  tipoDeAtendimentoRealizado: [
    "Desenvolvimento de funções cognitivas",
    "Desenvolvimento de vida autônoma",
    "Enriquecimento curricular",
    "Ensino da informática acessível",
    "Ensino da língua brasileira de sinais",
    "Ensino da língua portuguesa como segunda língua",
    "Ensino de técnicas de orientação e mobilidade",
    "Ensino de comunicação alternativa e aumentativa",
    "Ensino do uso de recursos ópticos e não ópticos",
  ],
  aspectosObservados: [
    {
      descricao: "Habilidades trabalhadas",
      form: "horas_trabalhadas",
    },
    {
      descricao: "Descrição dos atendimentos/atividades",
      form: "descricao_atividades",
    },
    {
      descricao: "Avanços observados",
      form: "avancos_observados",
    },
    {
      descricao: "Dificuldades observadas",
      form: "dificuldades_observadas",
    },
    {
      descricao: "Frequência e participação nas atividades",
      form: "participacao_atividades",
    },
    {
      descricao: "Frequência e envolvimento da família",
      form: "envolvimento_da_familia",
    },
  ],
  diasDaSemana: ["Domingo", "Segunda", "Terça", "Segunda", "Quarta", "Quinta", "Sexta", "Sábado"],
  tiposDeAtendimento: [
    "Escolarização",
    "Atendimento educacional especializado (AEE)",
    "Atividade complementar",
  ],
  modalidades: [
    "Ensino regular",
    "Educação especial - modalidade substitutiva",
    "Educação de jovens e adultos",
    "Educação profissional",
  ],

  situacoesMatricula: [
    "CURSANDO",
    // "APROVADO",
    "INATIVO",
    // "REPROVADO",
    "FALECIDO",
    "ABANDONO",
    "TRANSFERIDO",
    "CANCELADO",
    "TROCA DE TURMA",
    "TRANSFERIDO OUTROS",
    "RECEBIDA",
  ],

  situacoesUsuarios: [
    {
      descricao: "ATIVO",
      cor: "success",
    },
    {
      descricao: "BLOQUEADO",
      cor: "error",
    },
  ],

  situacoesMatriculaCondition: {
    CURSANDO: false,
    INATIVO: true,
    FALECIDO: true,
    ABANDONO: true,
    TRANSFERIDO: true,
    CANCELADO: true,
    TROCA_DE_TURMA: true,
    TRANSFERIDO_OUTROS: true,
  },

  vinculo: [
    {
      desc: "Efetivo",
      value: 0,
    },
    {
      desc: "Celetista",
      value: 1,
    },
  ],

  bimestres: [
    {
      desc: "1° bimestre",
      value: 0,
    },
    {
      desc: "2° bimestre",
      value: 1,
    },
    {
      desc: "3° bimestre",
      value: 2,
    },
    {
      desc: "4° bimestre",
      value: 3,
    },
  ],

  fichaEducacaoFundamentalEspecial: {
    habilidades_congnitivas: [
      {
        descricao: "REALIZA AS TAREFAS SOZINHO",
        form: "realiza_tarefas_sozinho",
      },
      {
        descricao: "MANTÉM A ATENÇÃO CONCENTRADA",
        form: "mantem_atencao",
      },
      {
        descricao: "CONCLUI AS SUAS TAREFAS ROTINEIRAS NO TEMPO ADEQUADO",
        form: "conclui_tarefas",
      },
      {
        descricao: "CONSEGUE USAR DIVERSAS HABILIDADES DE INTERPRETAÇÃO DE TEXTOS",
        form: "habilidades_interpretacao_texto",
      },
      {
        descricao: "CONSEGUE FAZER OPERAÇÕES NUMÉRICAS COMPLEXAS",
        form: "consegue_fazer_operacao_complexa",
      },
      {
        descricao: "LEMBRA O CONTEÚDOS DAS AULAS",
        form: "lembra_conteudo_aulas",
      },
      {
        descricao: "REALIZA OPERAÇÕES DE RACIOCÍNIO INDUTIVO",
        form: "realiza_operacoes_racicionio",
      },
      {
        descricao: "PRODUZ TEXTOS E RESPOSTAS COM COERÊNCIA",
        form: "produz_text_com_coerencia",
      },
      {
        descricao: "FAZ APRESENTAÇÕES ORAIS",
        form: "faz_apresentacoes_orais",
      },
    ],
    habilidades_interpessoais: [
      {
        descricao: "CONSEGUE SEGUIR AS REGRAS ESCOLARES",
        form: "consegue_seguir_regras_escolares",
      },
      {
        descricao: "INTERAGE COM OS COLEGAS E COM O PROFESSOR",
        form: "interage_colegas_professor",
      },
      {
        descricao: "RESPEITA TODOS NO AMBIENTE ESCOLAR",
        form: "respeita_todos_no_ambiente_escolar",
      },
      {
        descricao: "EXPRESSA EMOÇÕES POSITIVAS",
        form: "expressa_emocoes_positivas",
      },
      {
        descricao: "TIRA SUAS DÚVIDAS DURANTE AS AULAS",
        form: "tira_suas_duvidas_durante_as_aulas",
      },
      {
        descricao: "RESPEITA OS TURNOS DE FALA: FAZ SILÊNCIO",
        form: "respeita_turnos_de_fala_faz_silencio",
      },
      {
        descricao: "MANTÉM O CONTROLE EMOCIONAL EM SITUAÇÕES DE TENSÃO",
        form: "mantem_controle_emocional_em_situacoes_de_tensao",
      },
      {
        descricao: "CONSEGUE RECEBER AFETO E FAZER AMIZADES",
        form: "consegue_receber_afeto_e_fazer_amizades",
      },
      {
        descricao: "APROVEITA O RECREIO NO PÁTIO",
        form: "aproveita_o_recreio_no_patio",
      },
    ],
    area_motora: [
      {
        descricao: "SEGURA E MANUSEIA OS OBJETOS DE ESTUDO",
        form: "segura_manuseia_objeto_de_estudo",
      },
      {
        descricao: "FICA SENTADO",
        form: "fica_sentado",
      },
      {
        descricao: "DESENHA COM CRIATIVIDADE E ORGANIZAÇÃO",
        form: "desenha_com_criatividade_e_organizacao",
      },
      {
        descricao: "UTILIZA A COORDENAÇÃO MOTORA FINA",
        form: "utiliza_coordenacao_motora_fina",
      },
      {
        descricao: "ACOMPANHA E PARTICIPA DE ATIVIDADES LÚDICAS",
        form: "acompanha_e_participa_de_atividades_lucidas",
      },
      {
        descricao: "CONSEGUE PRONUNCIAR AS PALAVRAS ADEQUADAMENTE",
        form: "consegue_pronunciar_palavras_adequadamente",
      },
      {
        descricao: "COPIA OS CONTEÚDOS DO QUADRO",
        form: "copia_conteudos_do_quadro",
      },
      {
        descricao: "SEGMENTA AS PALAVRAS",
        form: "segmenta_palavras",
      },
      {
        descricao: "CONTROLA SEUS MOVIMENTOS E SUA FORÇA",
        form: "controla_seus_movimentos_e_sua_forca",
      },
    ],
  },

  fichaEducacaoInfantilEspecial: {
    area_congnitiva: [
      {
        descricao: "PERCEBE SIMILARIDADES E DIFERENÇAS EM FIGURAS OU OBJETOS",
        form: "percebe_similaridades_e_diferencas",
      },
      {
        descricao: "OUVE HISTÓRIAS COM ATENÇÃO",
        form: "ouve_historia_com_atencao",
      },
      {
        descricao: "CONCLUI AS SUAS TAREFAS ROTINEIRAS",
        form: "conclui_tarefas",
      },
      {
        descricao: "IDENTIFICA LETRAS E NÚMEROS",
        form: "identifica_letras_e_numeros",
      },
      {
        descricao: "PERMANECE NA SALA DE AULA",
        form: "permanece_sala_de_aula",
      },
      {
        descricao: "LEMBRA CONTEÚDOS DAS AULAS ANTERIORES",
        form: "lembra_conteudo_aulas",
      },
      {
        descricao: "CONSEGUE TER NOÇÃO DE ORDENAMENTO LÓGICO",
        form: "consegue_ter_nocao_ordenamento",
      },
      {
        descricao: "COMPREENDE AS ORIENTAÇÕES DOS ADULTOS",
        form: "compreende_orientacoes_dos_adultos",
      },
      {
        descricao: "RECONHECE SEU NOME",
        form: "reconhece_nome",
      },
    ],
    area_socioafetivas: [
      {
        descricao: "CONSEGUE SEGUIR AS REGRAS ESCOLARES",
        form: "consegue_seguir_regras_escolares",
      },
      {
        descricao: "INTERAGE COM OS COLEGAS",
        form: "interage_colegas",
      },
      {
        descricao: "OBEDECE A TODOS OS ADULTOS",
        form: "obedece_todos_os_adultos",
      },
      {
        descricao: "EXPRESSA EMOÇÕES POSITIVAS",
        form: "expressa_emocoes_positivas",
      },
      {
        descricao: "COMUNICA-SE VERBALMENTE COM EFICIÊNCIA",
        form: "comunica_se_verbalmente_com_eficiencia",
      },
      {
        descricao: "UTILIZA GESTOS E OUTROS SINAIS PARA SE EXPRESSAR",
        form: "utiliza_gestos_e_outros_sinais_para_se_expressar",
      },
      {
        descricao: "MANTÉM O CONTROLE EMOCIONAL AO SER CONTRARIADO",
        form: "mantem_o_controle_emocional",
      },
      {
        descricao: "PARTICIPA DAS FESTIVIDADES DA ESCOLA",
        form: "participa_da_festividades_da_escola",
      },
      {
        descricao: "APROVEITA O RECREIO NO PÁTIO",
        form: "aproveita_o_recreio_no_patio",
      },
    ],
    area_motora: [
      {
        descricao: "MANTÉM POSTURA E EQUILÍBRIO",
        form: "mantem_postura_e_equilibrio",
      },
      {
        descricao: "CONSEGUE PARAR E FICAR SENTADO",
        form: "consegue_parar_e_ficar_sentado",
      },
      {
        descricao: "SEGURA BEM OS LÁPIS E MATERIAIS DIVERSOS",
        form: "segura_bem_os_lapis",
      },
      {
        descricao: "DESENHA PESSOAS, ANIMAIS E OBJETOS PARECIDOS COM A FORMA REAL",
        form: "desenhas_animais_pessoas_e_objetos",
      },
      {
        descricao: "ORGANIZA SEU PRÓPRIO MATERIAL",
        form: "organiza_seu_proprio_material",
      },
      {
        descricao: "CONSEGUE ACOMPANHAR OS JOGOS LÚDICOS",
        form: "consegue_acompanhar_jogos_lucido",
      },
      {
        descricao: "ENCAIXA PEÇAS E PARTES DE FIGURAS",
        form: "encaixa_pecas_e_partes_de_figuras",
      },
      {
        descricao: "ESCREVE SEU NOME",
        form: "escreve_seu_nome",
      },
      {
        descricao: "REPRODUZ MOVIMENTO CORPORAL NO MESMO RITMO",
        form: "reproduz_movimento_corporal_no_mesmo_ritmo",
      },
    ],
  },

  FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_FUNDAMENTAL_FINAIS: {
    habilidades_congnitivas: [
      {
        descricao: "REALIZA AS TAREFAS SOZINHO",
        form: "realiza_tarefas_sozinho",
      },
      {
        descricao: "MANTÉM A ATENÇÃO CONCENTRADA",
        form: "mantem_atencao",
      },
      {
        descricao: "CONCLUI AS SUAS TAREFAS ROTINEIRAS NO TEMPO ADEQUADO",
        form: "conclui_tarefas",
      },
      {
        descricao: "CONSEGUE USAR DIVERSAS HABILIDADES DE INTERPRETAÇÃO DE TEXTOS",
        form: "habilidades_interpretacao_texto",
      },
      {
        descricao: "CONSEGUE FAZER OPERAÇÕES NUMÉRICAS COMPLEXAS",
        form: "consegue_fazer_operacao_complexa",
      },
      {
        descricao: "LEMBRA O CONTEÚDOS DAS AULAS",
        form: "lembra_conteudo_aulas",
      },
      {
        descricao: "REALIZA OPERAÇÕES DE RACIOCÍNIO INDUTIVO",
        form: "realiza_operacoes_racicionio",
      },
      {
        descricao: "PRODUZ TEXTOS E RESPOSTAS COM COERÊNCIA",
        form: "produz_text_com_coerencia",
      },
      {
        descricao: "FAZ APRESENTAÇÕES ORAIS",
        form: "faz_apresentacoes_orais",
      },
    ],

    habilidades_interpessoais: [
      {
        descricao: "CONSEGUE SEGUIR AS REGRAS ESCOLARES",
        form: "consegue_seguir_regras_escolares",
      },
      {
        descricao: "INTERAGE COM OS COLEGAS E COM O PROFESSOR",
        form: "interage_colegas_professor",
      },
      {
        descricao: "RESPEITA TODOS NO AMBIENTE ESCOLAR",
        form: "respeita_todos_no_ambiente_escolar",
      },
      {
        descricao: "EXPRESSA EMOÇÕES POSITIVAS",
        form: "expressa_emocoes_positivas",
      },
      {
        descricao: "TIRA SUAS DÚVIDAS DURANTE AS AULAS",
        form: "tira_suas_duvidas_durante_as_aulas",
      },
      {
        descricao: "RESPEITA OS TURNOS DE FALA: FAZ SILÊNCIO",
        form: "respeita_turnos_de_fala_faz_silencio",
      },
      {
        descricao: "MANTÉM O CONTROLE EMOCIONAL EM SITUAÇÕES DE TENSÃO",
        form: "mantem_controle_emocional_em_situacoes_de_tensao",
      },
      {
        descricao: "CONSEGUE RECEBER AFETO E FAZER AMIZADES",
        form: "consegue_receber_afeto_e_fazer_amizades",
      },
      {
        descricao: "APROVEITA O RECREIO NO PÁTIO",
        form: "aproveita_o_recreio_no_patio",
      },
    ],

    area_motora: [
      {
        descricao: "SEGURA E MANUSEIA OS OBJETOS DE ESTUDO",
        form: "segura_manuseia_objeto_de_estudo",
      },
      {
        descricao: "FICA SENTADO",
        form: "fica_sentado",
      },
      {
        descricao: "DESENHA COM CRIATIVIDADE E ORGANIZAÇÃO",
        form: "desenha_com_criatividade_e_organizacao",
      },
      {
        descricao: "UTILIZA A COORDENAÇÃO MOTORA FINA",
        form: "utiliza_coordenacao_motora_fina",
      },
      {
        descricao: "ACOMPANHA E PARTICIPA DE ATIVIDADES LÚDICAS",
        form: "acompanha_e_participa_de_atividades_lucidas",
      },
      {
        descricao: "CONSEGUE PRONUNCIAR AS PALAVRAS ADEQUADAMENTE",
        form: "consegue_pronunciar_palavras_adequadamente",
      },
      {
        descricao: "COPIA OS CONTEÚDOS DO QUADRO",
        form: "copia_conteudos_do_quadro",
      },
      {
        descricao: "SEGMENTA AS PALAVRAS",
        form: "segmenta_palavras",
      },
      {
        descricao: "CONTROLA SEUS MOVIMENTOS E SUA FORÇA",
        form: "controla_seus_movimentos_e_sua_forca",
      },
    ],
  },

  FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_FUNDAMENTAL_INICIAS: {
    area_cognitiva: [
      {
        descricao: "REALIZA AS TAREFAS ESCOLARES SOZINHO",
        form: "realiza_tarefas_sozinho",
      },
      {
        descricao: "ORGANIZA SEU DISCURSO PARA CONTAR FATOS DO DIA A DIA",
        form: "organiza_seu_discurso",
      },
      {
        descricao: "CONCLUI AS SUAS TAREFAS ROTINEIRAS NO TEMPO ADEQUADO",
        form: "conclui_suas_tarefas_rotineiras",
      },
      {
        descricao: "CONSEGUE UTILIZAR HABILIDADES DE LEITURA DIVERSAS",
        form: "consegue_utilizar_habilidades_de_leituras_diversas",
      },
      {
        descricao: "REALIZA OPERAÇÕES DE RACIOCÍNIO INDUTIVO",
        form: "realiza_operacoes_de_raciocinio_indutivo",
      },
      {
        descricao: "LEMBRA OS CONTEÚDOS DAS AULAS",
        form: "lembra_conteudos_das_aulas",
      },
      {
        descricao: "CONSEGUE TER NOÇÃO DE TEMPO, ORDENAMENTO E ESPAÇO",
        form: "consegue_ter_nocao_tempo_ordenamento_e_espaco",
      },
      {
        descricao: "CONSEGUE FAZER OPERAÇÕES NUMÉRICAS COMPLEXAS SOZINHO",
        form: "consegue_fazer_operacoes_numericas_complexas_sozinho",
      },
      {
        descricao: "MANTÉM A ATENÇÃO DURANTE A AULA",
        form: "mantem_atencao_durante_a_aula",
      },
    ],

    area_socioafetiva: [
      {
        descricao: "SEGUE AS REGRAS ESCOLARES",
        form: "segue_regras_escolares",
      },
      {
        descricao: "INTERAGE COM TODOS NO AMBIENTE ESCOLAR",
        form: "interage_com_todos",
      },
      {
        descricao: "OBEDECE A TODOS OS ADULTOS",
        form: "respeita_todos_no_ambiente_escolar",
      },
      {
        descricao: "EXPRESSA EMOÇÕES POSITIVAS",
        form: "expressa_emocoes_positivas",
      },
      {
        descricao: "COMUNICA-SE VERBALMENTE COM EFICIÊNCIA",
        form: "comunica_verbalmente_com_deficiencia",
      },
      {
        descricao: "UTILIZA GESTOS E OUTROS SINAIS PARA SE EXPRESSAR",
        form: "utiliza_gestos_e_outros_sinais_para_se_expressar",
      },
      {
        descricao: "MANTÉM O CONTROLE EMOCIONAL EM SITUAÇÕES DE TENSÃO",
        form: "mantem_controle_emocional_em_situacoes_de_tensao",
      },
      {
        descricao: "CONSEGUE RECEBER AFETO E FAZER AMIZADES",
        form: "consegue_receber_afeto_e_fazer_amizades",
      },
      {
        descricao: "APROVEITA O RECREIO NO PÁTIO",
        form: "aproveita_o_recreio_no_patio",
      },
    ],

    area_motora: [
      {
        descricao: "SEGURA CORRETAMENTE LÁPIS E OBJETOS DE ESTUDO",
        form: "segura_corretamente_lapis_e_objetos_de_estudos",
      },
      {
        descricao: "FICA SENTADO NA SALA",
        form: "fica_sentado_na_sala",
      },
      {
        descricao: "DESENHA E PINTA COM CRIATIVIDADE E ORGANIZAÇÃO",
        form: "desenha_e_pinta_com_criatividade_e_organizacao",
      },
      {
        descricao: " UTILIZA A COORDENAÇÃO MOTORA FINA",
        form: "utiliza_coordenacao_motora_fina",
      },
      {
        descricao: "ORGANIZA SEU PRÓPRIO MATERIAL ESCOLAR",
        form: "organizar_seu_proprio_material_escolar",
      },
      {
        descricao: "CONSEGUE ACOMPANHAR OS JOGOS LÚDICOS",
        form: "consegue_acompanhar_jogos_lucidos",
      },
      {
        descricao: "COPIA AS ATIVIDADES DO QUADRO",
        form: "capia_as_atividades_do_quadro",
      },
      {
        descricao: "SEGMENTA AS PALAVRAS",
        form: "segmenta_palavras",
      },
      {
        descricao: "CONTROLA SEUS MOVIMENTOS E SUA FORÇA",
        form: "controla_seus_movimentos_e_sua_forca",
      },
    ],
  },

  FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_INFANTIL: {
    area_cognitiva: [
      {
        descricao: "PERCEBE SIMILARIDADES E DIFERENÇAS EM FIGURAS OU OBJETOS",
        form: "percebe_similaridades_e_diferencas_em_figuras_ou_objetos",
      },
      {
        descricao: "OUVE HISTÓRIAS COM ATENÇÃO",
        form: "ouve_historias_com_atencao",
      },
      {
        descricao: "CONCLUI AS SUAS TAREFAS ROTINEIRAS",
        form: "conclui_suas_tarefas_rotineiras",
      },
      {
        descricao: "IDENTIFICA LETRAS E NÚMEROS",
        form: "identifica_letras_e_numeros",
      },
      {
        descricao: "PERMANECE NA SALA DE AULA",
        form: "permanece_na_sala_de_aula",
      },
      {
        descricao: "LEMBRA CONTEÚDOS DAS AULAS ANTERIORES",
        form: "lembra_conteudos_das_anteriores",
      },
      {
        descricao: "CONSEGUE TER NOÇÃO DE ORDENAMENTO LÓGICO",
        form: "consegue_ter_nocao_de_ordenamento_logico",
      },
      {
        descricao: "COMPREENDE AS ORIENTAÇÕES DOS ADULTOS",
        form: "compreende_as_orientacoes_dos_adultos",
      },
      {
        descricao: "RECONHECE SEU NOME",
        form: "reconhece_seu_nome",
      },
    ],

    area_socioafetiva: [
      {
        descricao: "CONSEGUE SEGUIR AS REGRAS ESCOLARES",
        form: "consegue_seguir_regras_escolares",
      },
      {
        descricao: "INTERAGE COM OS COLEGAS",
        form: "interage_colegas_professor",
      },
      {
        descricao: "OBEDECE A TODOS OS ADULTOS",
        form: "obedece_a_todos_os_adultos",
      },
      {
        descricao: "EXPRESSA EMOÇÕES POSITIVAS",
        form: "expressa_emocoes_positivas",
      },
      {
        descricao: "COMUNICA-SE VERBALMENTE COM EFICIÊNCIA",
        form: "comunica_se_verbalmente_com_deficienca",
      },
      {
        descricao: "UTILIZA GESTOS E OUTROS SINAIS PARA SE EXPRESSAR",
        form: "utiliza_gestoes_e_outros_sinais_para_se_expressar",
      },
      {
        descricao: "MANTÉM O CONTROLE EMOCIONAL AO SER CONTRARIADO",
        form: "mantem_controle_emocional_em_situacoes_de_tensao",
      },
      {
        descricao: "PARTICIPA DAS FESTIVIDADES DA ESCOLA",
        form: "participa_das_atividades_da_escola",
      },
      {
        descricao: "APROVEITA O RECREIO NO PÁTIO",
        form: "aproveita_o_recreio_no_patio",
      },
    ],

    area_motora: [
      {
        descricao: "MANTÉM POSTURA E EQUILÍBRIO",
        form: "mantem_postura_e_equilibrio",
      },
      {
        descricao: "CONSEGUE PARAR E FICAR SENTADO",
        form: "consegue_parar_e_ficar_sentado",
      },
      {
        descricao: "SEGURA BEM OS LÁPIS E MATERIAIS DIVERSOS",
        form: "segura_bem_os_lapis_e_materiais_diversos",
      },
      {
        descricao: "DESENHA PESSOAS, ANIMAIS E OBJETOS PARECIDOS COM A FORMA REAL",
        form: "desenha_pessoas_animais_e_objetos_parecidos_com_a_forma_real",
      },
      {
        descricao: "ORGANIZA SEU PRÓPRIO MATERIAL",
        form: "organiza_seu_proprio_material",
      },
      {
        descricao: "CONSEGUE ACOMPANHAR OS JOGOS LÚDICOS",
        form: "consegue_acompanhar_os_jogos_lucidos",
      },
      {
        descricao: "ENCAIXA PEÇAS E PARTES DE FIGURAS",
        form: "encaixa_pecas_e_partes_de_figuras",
      },
      {
        descricao: "ESCREVE SEU NOME",
        form: "escreve_seu_nome",
      },
      {
        descricao: "REPRODUZ MOVIMENTO CORPORAL NO MESMO RITMO",
        form: "reproduz_movimento_corporal_no_mesmo_ritmo",
      },
    ],
  },

  fichaEducacaoEspecialMensal: [
    {
      descricao: "CAPACIDADES E INTERESSES",
      form: "capacidades_interesses",
    },
    {
      descricao: "NECESSIDADES E PRIORIDADES",
      form: "necessidades_e_prioridades",
    },
    {
      descricao: "METAS",
      form: "metas",
    },
    {
      descricao: "RECURSOS",
      form: "recursos",
    },
  ],

  fichaEducacaoEspecialDiario: [
    {
      descricao: "METAS",
      form: "metas",
    },
    {
      descricao: "ATIVIDADES",
      form: "atividades",
    },
  ],

  bimestresEducacaoInclusiva: [
    {
      id: 1,
      descricao: "BIMESTRE 01",
    },
    {
      id: 2,
      descricao: "BIMESTRE 02",
    },
    {
      id: 3,
      descricao: "BIMESTRE 03",
    },
    {
      id: 4,
      descricao: "BIMESTRE 04",
    },
  ],

  plano_bimestrais_fundamental: [
    {
      descricao: "UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM",
      form: "tematica",
    },
    {
      descricao: "OBJETOS DO CONHECIMENTO",
      form: "objeto_conhecimento",
    },
    {
      descricao: "HABILIDADES",
      form: "habilidades",
    },
    {
      descricao: "METODOLOGIA",
      form: "metodologia",
    },
    {
      descricao: "RECURSOS DIDÁTICOS",
      form: "recursos_didaticos",
    },
    {
      descricao: "AVALIAÇÃO DA APRENDIZAGEM",
      form: "avaliacao_aprendizagem",
    },
    {
      descricao: "REFERÊNCIAS",
      form: "referencias",
    },
  ],

  plano_bimestrais_infantil: [
    {
      descricao: "CAMPOS DE EXPERIÊNCIAS",
      form: "campo_experiencia",
    },
    {
      descricao: "OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO",
      form: "objeto_aprendizagem",
    },
    {
      descricao: "HABILIDADES",
      form: "habilidades",
    },
    {
      descricao: "OBJETO DO CONHECIMENTO",
      form: "objeto_conhecimento",
    },
  ],

  tipos_pesquisa_aluno: [
    {
      desc: "Pai",
      value: "filiacao2",
    },
    {
      desc: "Mãe",
      value: "filiacao1",
    },
    {
      desc: "Data de nasc",
      value: "data_nascimento",
    },
    {
      desc: "Sexo",
      value: "sexo_id",
    },
    {
      desc: "Cor/Raça",
      value: "cor_ou_raca",
    },
    {
      desc: "Nacionalidade",
      value: "naturalidade",
    },
    {
      desc: "País de nacionalidade",
      value: "nacionalidade",
    },
    {
      desc: "UF de nascimento",
      value: "estadualidade",
    },
  ],
  meses: [
    {
      descricao: "Janeiro",
      id: 1,
    },
    {
      descricao: "Fevereiro",
      id: 2,
    },
    {
      descricao: "Março",
      id: 3,
    },
    {
      descricao: "Abril",
      id: 4,
    },
    {
      descricao: "Maio",
      id: 5,
    },
    {
      descricao: "Junho",
      id: 6,
    },
    {
      descricao: "Julho",
      id: 7,
    },
    {
      descricao: "Agosto",
      id: 8,
    },
    {
      descricao: "Setembro",
      id: 9,
    },
    {
      descricao: "Outubro",
      id: 10,
    },
    {
      descricao: "Novembro",
      id: 11,
    },
    {
      descricao: "Dezembro",
      id: 12,
    },
  ],
  bimestresRendimentos: [
    {
      descricao: "1º BIMESTRE",
      id: 1,
    },
    {
      descricao: "2º BIMESTRE",
      id: 2,
    },
    {
      descricao: "RESULTADO DO 1º SEMESTRE",
      id: 3,
    },
    {
      descricao: "3º BIMESTRE",
      id: 4,
    },
    {
      descricao: "4º BIMESTRE",
      id: 5,
    },
    {
      descricao: "RESULTADO DO 2º SEMESTRE",
      id: 6,
    },
    {
      descricao: "RESULTADO FINAL",
      id: 7,
    },
    {
      descricao: "LISTA EM BRANCO",
      id: 8,
    },
  ],
  sistemaDeNotas: ["Numerica", "Relatório Infantil", "Relatório Individual"],
  atividadesComplementaresList: [
    {
      codigo: 11002,
      descricao: "Canto coral",
    },
    {
      codigo: 11006,
      descricao: "Banda",
    },
    {
      codigo: 11011,
      descricao: "Iniciação Musical",
    },
    {
      codigo: 12003,
      descricao: "Desenho",
    },
    {
      codigo: 12004,
      descricao: "Escultura e Cerâmica",
    },
    {
      codigo: 12005,
      descricao: "Grafite",
    },
    {
      codigo: 12007,
      descricao: "Pintura",
    },
    {
      codigo: 13001,
      descricao: "Cineclube",
    },
    {
      codigo: 14001,
      descricao: "Teatro",
    },
    {
      codigo: 14002,
      descricao: "Danças",
    },
    {
      codigo: 14004,
      descricao: "Práticas Circenses",
    },
    {
      codigo: 15001,
      descricao: "Capoeira",
    },
    {
      codigo: 15002,
      descricao: "Artesanato",
    },
    {
      codigo: 15003,
      descricao: "Brinquedos",
    },
    {
      codigo: 15004,
      descricao: "Contos",
    },
    {
      codigo: 16001,
      descricao: "Educação Patrimonial",
    },
    {
      codigo: 17004,
      descricao: "Leitura",
    },
    {
      codigo: 17002,
      descricao: "Línguas Estrangeiras",
    },
    {
      codigo: 19999,
      descricao: "Outra categoria de Cultura, Artes e Educação Patrimonial",
    },
    {
      codigo: 21001,
      descricao: "Recreação (Brinquedoteca e Jogos)",
    },
    {
      codigo: 22007,
      descricao: "Yoga",
    },
    {
      codigo: 22009,
      descricao: "Tênis de campo",
    },
    {
      codigo: 22011,
      descricao: "Atletismo",
    },
    {
      codigo: 22012,
      descricao: "Badminton",
    },
    {
      codigo: 22014,
      descricao: "Basquete",
    },
    {
      codigo: 22015,
      descricao: "Ciclismo",
    },
    {
      codigo: 22018,
      descricao: "Futebol",
    },
    {
      codigo: 22019,
      descricao: "Futsal",
    },
    {
      codigo: 22020,
      descricao: "Ginástica(rítmica, artística, acrobática)",
    },
    {
      codigo: 22021,
      descricao: "Handebol",
    },
    {
      codigo: 22022,
      descricao: "Judô",
    },
    {
      codigo: 22023,
      descricao: "Karatê",
    },
    {
      codigo: 22024,
      descricao: "Luta Olímpica",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 22026,
      descricao: "Taekwondo",
    },
    {
      codigo: 22027,
      descricao: "Tênis de Mesa",
    },
    {
      codigo: 22028,
      descricao: "Voleibol",
    },
    {
      codigo: 22029,
      descricao: "Vôlei de Praia",
    },
    {
      codigo: 22030,
      descricao: "Xadrez Tradicional/xadrez virtual",
    },
    {
      codigo: 29999,
      descricao: "Outra categoria de Esporte e Lazer",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 31002,
      descricao: "Português",
    },
    {
      codigo: 31001,
      descricao: "Matemática",
    },
    {
      codigo: 39999,
      descricao: "Outra categoria de Acompanhamento Pedagógico",
    },
    {
      codigo: 41007,
      descricao: "Educação em Direitos Humanos",
    },
    {
      codigo: 71007,
      descricao: "Promoção da Saúde",
    },
    {
      codigo: 10103,
      descricao: "Iniciação Científica",
    },
    {
      codigo: 13301,
      descricao: "Educação Ambiental e Desenvolvimento Sustentável",
    },
    {
      codigo: 13108,
      descricao: "Economia Solidária e Criativa/Educação Econômica (Educação Financeira e Fiscal)",
    },
    {
      codigo: 14101,
      descricao: "Fotografia",
    },
    {
      codigo: 14102,
      descricao: "História em Quadrinhos",
    },
    {
      codigo: 14103,
      descricao: "Jornal Escolar",
    },
    {
      codigo: 14104,
      descricao: "Rádio Escolar",
    },
    {
      codigo: 22025,
      descricao: "Vídeo",
    },
    {
      codigo: 14201,
      descricao: "Robótica Educacional",
    },
    {
      codigo: 14202,
      descricao: "Tecnologias Educacionais",
    },
    {
      codigo: 14203,
      descricao: "Ambientes de Redes Sociais",
    },
    {
      codigo: 14999,
      descricao: "Outra Categoria de Comunicação, Uso de Mídias e Cultura Digital e Tecnológica",
    },
    {
      codigo: 15101,
      descricao: "Memória e História das Comunidades Tradicionais",
    },
  ],
  sexoList: [
    {
      id: 1,
      descricao: "Masculino",
    },
    {
      id: 2,
      descricao: "Feminino",
    },
  ],
  corOuRacaList: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Não declarada"],
  nacionalidadeList: [
    "Brasileira",
    "Brasileira – nascido no exterior ou naturalizado",
    "Estrangeira",
  ],
  estadosList: [
    { codigo: "AC", descricao: "Acre" },
    { codigo: "AL", descricao: "Alagoas" },
    { codigo: "AP", descricao: "Amapá" },
    { codigo: "AM", descricao: "Amazonas" },
    { codigo: "BA", descricao: "Bahia" },
    { codigo: "CE", descricao: "Ceará" },
    { codigo: "DF", descricao: "Distrito Federal" },
    { codigo: "ES", descricao: "Espírito Santo" },
    { codigo: "GO", descricao: "Goías" },
    { codigo: "MA", descricao: "Maranhão" },
    { codigo: "MT", descricao: "Mato Grosso" },
    { codigo: "MS", descricao: "Mato Grosso do Sul" },
    { codigo: "MG", descricao: "Minas Gerais" },
    { codigo: "PA", descricao: "Pará" },
    { codigo: "PB", descricao: "Paraíba" },
    { codigo: "PR", descricao: "Paraná" },
    { codigo: "PE", descricao: "Pernambuco" },
    { codigo: "PI", descricao: "Piauí" },
    { codigo: "RJ", descricao: "Rio de Janeiro" },
    { codigo: "RN", descricao: "Rio Grande do Norte" },
    { codigo: "RS", descricao: "Rio Grande do Sul" },
    { codigo: "RO", descricao: "Rondônia" },
    { codigo: "RR", descricao: "Roraíma" },
    { codigo: "SC", descricao: "Santa Catarina" },
    { codigo: "SP", descricao: "São Paulo" },
    { codigo: "SE", descricao: "Sergipe" },
    { codigo: "TO", descricao: "Tocantins" },
  ],
  municipios,
  tipoDeDeficienciaList: [
    "Baixa visão",
    "Cegueira",
    "Deficiência auditiva",
    "Deficiência física",
    "Deficiência intelectual",
    "Deficiência Múltipla",
    "Epilepsia",
    "Síndrome de Down",
    "Surdez",
    "Surdocegueira",
  ],
  tipoDeTranstornoDoEspectroAutistaList: ["Transtorno do espectro autista"],
  tipoDeAltasHabilidadesList: ["Altas habilidades/Superdotação"],
  recursosSaebList: [
    "Auxílio ledor",
    "Tradutor-intérprete de Libras",
    "Prova superampliada (Fonte 24)",
    "Auxílio transcrição",
    "Leitura labial",
    "CD com áudio para deficiente visual",
    "Guia-intérprete",
    "Prova ampliada (Fonte 18)",
    "Prova de Língua Portuguesa como Segunda Língua para surdos e deficientes auditivos",
    "Prova em Video Libras",
    "Material didático e prova em Braille",
    "Nenhum",
  ],
  zonaResidenciaList: ["Urbana", "Rural"],
  localizacaoDiferenciadaDeResidenciaList: [
    "Não está em área de localização diferenciada",
    "Área onde se localiza comunidade remanescente de quilombos",
    "Terra indígena",
    "Área de assentamento",
  ],
  rolesParaProfessores: [
    {
      id: "2",
      name: "professor",
    },
    {
      id: "11",
      name: "professor-complementar",
    },
    {
      id: "5",
      name: "coordenador",
    },
    {
      id: "7",
      name: "direção",
    },
    {
      id: "8",
      name: "supervisor",
    },
  ],

  rolesParaGestores: [
    {
      id: "1",
      name: "admin",
    },
    {
      id: "4",
      name: "secretario",
    },
    {
      id: "6",
      name: "super-admin",
    },
    {
      id: "9",
      name: "tec-admin",
    },
  ],

  tiposDeAulas: [
    "Aula Remota",
    "Aula Normal",
    "Reposição",
    "Aula Extra",
    "Substituição",
    "Aula Antecipada",
    "Atividade Extra-classe",
    "Recuperação",
  ],
  situacoesAulas: [
    {
      descricao: "Aula confirmada",
      cor: "success",
    },
    {
      descricao: "Aguardando confirmação",
      cor: "blue-grey lighten-4",
    },
    {
      descricao: "Aula rejeitada por falta",
      cor: "error",
    },
    {
      descricao: "Aula inválida",
      cor: "brown lighten-3",
    },
    {
      descricao: "Aula em conflito",
      cor: "warning",
    },
  ],
  situacoesAulasTabs: [
    {
      descricao: "Confirmadas",
      cor: "green lighten-4",
      tipo: "Aula confirmada",
    },
    {
      descricao: "Aguardando",
      cor: "blue-grey lighten-4",
      tipo: "Aguardando confirmação",
    },
    {
      descricao: "Rejeitadas",
      cor: "red lighten-4",
      tipo: "Aula rejeitada por falta",
    },
    {
      descricao: "Inválidas",
      cor: "brown lighten-5",
      tipo: "Aula inválida",
    },
    {
      descricao: "Conflitadas",
      cor: "amber lighten-4",
      tipo: "Aula em conflito",
    },
  ],
  situacoesAulasCor: {
    "Aula confirmada": "green lighten-2",
    "Aguardando confirmação": "blue-grey lighten-2",
    "Aula rejeitada por falta": "red lighten-2",
    "Aula inválida": "brown lighten-3",
    "Aula em conflito": "amber lighten-2",
  },

  subEtapaTipoCampos: [
    {
      descricao: "Valor (Inserir) - Número",
      slug: "valor",
    },
    {
      descricao: "Valor (Inserir) - Texto",
      slug: "valor_texto",
    },
    {
      descricao: "Resultado em número",
      slug: "resultado_em_lote",
    },
    {
      descricao: "Resultado em Texto",
      slug: "resultado_em_texto",
    },
  ],
  situacaoAutorizacao: ["PENDENTE", "RECUSADO", "APROVADO"],
  coresPrimarias: [
    {
      descricao: "Verde",
      codigo: "#00FF00",
    },
    {
      descricao: "Vermelho",
      codigo: "#FF0000",
    },
    {
      descricao: "Azul",
      codigo: "#0000FF",
    },
    {
      descricao: "Preto",
      codigo: "black",
    },
    {
      descricao: "Rosa",
      codigo: "pink",
    },
  ],
  cores: [
    {
      descricao: "Preto",
      codigo: "#000",
    },
    {
      descricao: "Vermelho",
      codigo: "red",
    },
    {
      descricao: "Azul",
      codigo: "blue",
    },
    {
      descricao: "Amarelo",
      codigo: "yellow",
    },
    {
      descricao: "Verde",
      codigo: "green",
    },
  ],
};

export default (vue) => {
  vue.prototype.$constants = $constants;
};
