<template>
  <div>
    <v-dialog
      v-model="dialogConfirmarAulas"
      :return-value.sync="data_intervalo"
      persistent
      width="700"
    >
      <v-card justify="center grey lighten-2">
        <v-card-title>
          PESQUISE AS AULAS POR UM INTERVALO DE DATAS
          <v-spacer></v-spacer>
          <v-btn icon @click="() => fecharModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="text-center">
          <v-row class="mt-2 mx-3">
            <v-col cols="12">
              <v-date-picker
                color="white primary--text text--darken"
                no-title
                full-width
                v-model="data_intervalo"
                range
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-col>

            <v-col>
              <v-divider></v-divider>
            </v-col>

            <template>
              <v-tabs fixed-tabs optional v-model="tab">
                <v-tab
                  v-for="(item, index) in $constants.situacoesAulasTabs"
                  :key="index"
                  @click="() => filtrarAulasSituacoes(item)"
                >
                  <v-badge
                    overlap
                    v-if="resumoAulas[item.tipo]"
                    :content="resumoAulas[item.tipo] ? resumoAulas[item.tipo] : 0"
                    :color="$constants.situacoesAulasCor[item.tipo]"
                  >
                    <span class="text-overline">{{ item.descricao }}</span>
                  </v-badge>
                  <span v-else class="text-overline">{{ item.descricao }}</span>
                </v-tab>
              </v-tabs>
            </template>
            <v-col cols="12">
              <v-timeline dense clipped>
                <v-timeline-item fill-dot class="white--text mb-12" color="primary" large>
                  <template v-slot:icon>
                    <v-icon color="white" x-small>fa fa-check</v-icon>
                  </template>
                  <v-text-field hide-details flat :value="''" disabled label="" solo>
                    <template v-slot:append>
                      <v-btn
                        class="mx-0"
                        v-if="listAulas.length > 0"
                        depressed
                        @click="() => (confirmDialog = !confirmDialog)"
                      >
                        Confirmar aulas
                      </v-btn>
                      <v-badge v-else bordered color="error" icon="mdi-lock" overlap>
                        <v-btn
                          disabled
                          class="white--text"
                          color="grey"
                          depressed
                          @click="confirmDialog = !confirmDialog"
                        >
                          Confirmar aulas
                        </v-btn>
                      </v-badge>
                    </template>
                  </v-text-field>
                </v-timeline-item>
                <v-timeline-item class="mb-6" hide-dot>
                  <strong>{{ listAulas.length > 0 ? "AULAS" : "AULAS NÃO ENCONTRADAS" }}</strong>
                </v-timeline-item>

                <v-timeline-item
                  v-for="(item, index) in listAulas"
                  :key="index"
                  class="mb-4"
                  :color="
                    $constants.situacoesAulasCor[
                      item.situacao ? item.situacao : 'Aguardando confirmação'
                    ]
                  "
                  icon-color="grey lighten-2"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="8" class="text-left">
                      <span class="text-caption grey--text"> #{{ item.id }} </span><br />

                      <span class="text-caption">
                        {{ item.data_formatada ? item.data_formatada : "[NÃO INFORMADO]" }} -
                        {{ item.horario ? item.horario.descricao : "[NÃO INFORMADO]" }} </span
                      ><br />
                      <span class="text-subtitle-2">
                        {{ item.conteudo ? item.conteudo : "[NÃO INFORMADO]" }}
                      </span>
                    </v-col>
                    <v-col class="text-right" cols="4">
                      <!-- <span>{{ item.situacao }}</span> -->
                      <e-situacao-aula :aula="item" :i="index" @alterarSituacao="alterarSituacao" />
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="() => fecharModal()"> Fechar </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProgressAulas" persistent width="500">
      <v-card class="mx-auto grey lighten-2" max-width="500" width="500">
        <v-progress-linear
          v-model="quantidades_confirmadas"
          :buffer-value="bufferValue"
          height="25"
        >
          <strong>{{ Math.ceil(quantidades_confirmadas) }}%</strong>
        </v-progress-linear>
        <v-card-text class="text-center">confirmando aulas...</v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="confirmarAulas"
    >
    </e-modal-confirm>
  </div>
</template>

<script>
export default {
  props: {
    dialogConfirmarAulas: {
      type: Boolean,
      default: false,
    },
    gestao_selected_to_interval: {
      required: true,
    },
  },
  watch: {
    async data_intervalo(val) {
      if (val.length > 1) {
        const response = await this.$services.gestoesDeAulasService.listarAulasIntervaloData(
          this.gestao_selected_to_interval.id,
          val
        );
        this.tab = null;
        this.listAulas = response.aulas ? response.aulas : [];
        this.listAulasCache = response.aulas ? response.aulas : [];
        this.quantidades_aulas = this.listAulas.length;
        this.resumoAulasLoad();
      }
    },
    quantidades_confirmadas(val) {
      if (val < 100) {
        return;
      }
      if (this.quantidades_confirmadas >= 100) {
        clearInterval(this.interval);
        this.dialogProgressAulas = false;
        this.$toast.success("Aulas confirmadas com sucesso");
        this.$emit("carregarGestoesNovamenteEmit", this.gestao_selected_to_interval.id);
        return;
      }
      this.quantidades_confirmadas = 0;
      this.bufferValue = 10;
      this.startBuffer();
    },
  },
  data() {
    return {
      input: "",
      data_intervalo: [],
      quantidades_confirmadas: 0,
      bufferValue: 20,
      interval: 0,
      listAulas: [],
      listAulasCache: [],
      quantidades_aulas: 0,
      confirmDialog: false,
      tab: null,
      objectModal: {
        title: "Confirmação de aulas",
        message:
          'Só seram afetadas as aulas que estão com a situação de "AGUARDANDO CONFIRMAÇÃO", deseja continuar?',
      },
      dialogProgressAulas: false,
      resumoAulas: [],
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
    },
    alterarSituacao(Objeto) {
      this.$set(this.listAulasCache, Objeto.i, Objeto.aulaAtualizada);
    },
    confirmarAulas() {
      this.confirmDialog = !this.confirmDialog;
      this.dialogProgress();
      this.listAulas.forEach(async (aula) => {
        if (this.quantidades_confirmadas >= 100) {
          clearInterval(this.interval);
          this.dialogProgressAulas = false;
          this.$toast.success("Aulas confirmadas com sucesso");
          return;
        }
        if (aula.situacao === "Aguardando confirmação" || !aula.situacao) {
          await this.$services.aulasService.atualizarSituacao(aula, "Aula confirmada");
        }
        await this.startBuffer();
      });
    },
    startBuffer() {
      clearInterval(this.interval);
      const porcetagem = 100 / this.quantidades_aulas;
      this.interval = setInterval(() => {
        this.quantidades_confirmadas += porcetagem;
        this.bufferValue += porcetagem + 1;
      }, 500);
    },
    async filtrarAulasSituacoes(situacao) {
      this.listAulas = this.listAulasCache.filter((f) => {
        const aula = f.situacao ? f.situacao : "Aguardando confirmação";
        return aula === situacao.tipo;
      });
      this.$forceUpdate();
    },
    resumoAulasLoad() {
      const resumo = this.listAulasCache.reduce((accumulator, aula) => {
        const situacao = aula.situacao || "Aguardando confirmação";
        accumulator[situacao] = Number.isInteger(accumulator[situacao])
          ? accumulator[situacao] + 1
          : 1;
        return accumulator;
      }, []);
      this.resumoAulas = resumo;
    },
    fecharModal() {
      this.$emit("dialogAulasChange", false);
      this.listAulas = [];
      this.tab = null;
    },
    dialogProgress() {
      this.quantidades_aulas = this.listAulas.length;
      if (this.quantidades_aulas <= 0) {
        this.$toast.warn('Número de aulas precisa ser maior que "0".');
        return;
      }
      if (this.quantidades_confirmadas >= 100) {
        this.quantidades_confirmadas = 0;
      }
      this.dialogProgressAulas = true;
      this.startBuffer();
    },
  },
};
</script>

<style lang="scss" scoped></style>
