import Axios from "@/plugins/Axios";

export class ImageServices {
  async uploadImage(url, file, id) {
    const formData = new FormData();
    formData.append("file", file);
    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });
    const response = await Axios().post(`${url}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  async downloadImage(url) {
    const base64 = await Axios()
      .get(url, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    // console.log("service...");
    return `data:image/jpeg;base64, ${base64}`;
  }

  async downloadImagemV2(url, id) {
    // Essa prepara a imagem pronta para ser mostrada no front-end
    const response = await Axios().get(`${url}/${id}`, { responseType: "blob" });
    try {
      const base64data = await this.blobToData(response.data);
      return base64data;
    } catch (error) {
      return error;
    }
  }

  async downloadImagemV3(url, nome_arquivo, id, extension) {
    // Já essa prepara a imagem para download direto ao clicar
    try {
      const response = await Axios()
        .get(`${url}/${id}`, { responseType: "arraybuffer" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/image*" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = nome_arquivo + extension;
          link.click();
        });
      return response;
    } catch (error) {
      return error;
    }
  }

  // Método usado para ler o formato base64
  async blobToData(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
export default new ImageServices();
